const config = useRuntimeConfig()

export const getCoordsByString = async (city: string | undefined) => {
  if (!city) return [37.64, 55.76]
  try {
    const { data } = await useFetch(
      `https://geocode-maps.yandex.ru/1.x?apikey=${config.public.env.Y_MAP_API_KEY}&geocode=${city}&results=1&format=json`,
    )
    const jsonData: any = data.value
    if (jsonData?.response?.GeoObjectCollection?.featureMember) {
      const pointValue =
        jsonData.response.GeoObjectCollection.featureMember[0]?.GeoObject?.Point?.pos
      if (pointValue) {
        return pointValue.split(' ').map(Number)
      } else {
        return [37.64, 55.76]
      }
    } else {
      return [37.64, 55.76]
    }
  } catch (e) {
    console.log(e)
    return [37.64, 55.76]
  }
}
